import React, { useEffect, useState } from "react";
import Layout from "../../page/layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getMe } from "../../features/authSlice";

const AddRencanaTanam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, user } = useSelector((state) => state.auth);

  const [tanggalRencanaTanam, settanggalRencanaTanam] = useState("");
  const [tanggalRencanaPanen, settanggalRencanaPanen] = useState("");
  const [varietassingkong, setvarietassingkong] = useState("");
  const [estimasiproduksi, setestimasiproduksi] = useState("");
  const [catatantambahan, setcatatantambahan] = useState("");
  const [jenispupuk, setjenispupuk] = useState("");
  const [jumlahpupuk, setjumlahpupuk] = useState("");
  const [lahanOptions, setLahanOptions] = useState([]);
  const [selectedLahan, setSelectedLahan] = useState("");
  const [msg, setMsg] = useState("");

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    try {
      const responseLahan = await axios.get(
        `https://c-greenproject.org:8000/lahan`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLahanOptions(responseLahan.data);
      console.log("Data lahan yang diterima:", responseLahan.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setMsg("Gagal mengambil data lahan atau harga.");
    }
  };

  const savePetani = async (e) => {
    e.preventDefault();

    if (
      !selectedLahan ||
      !tanggalRencanaTanam ||
      !tanggalRencanaPanen ||
      !varietassingkong ||
      !estimasiproduksi ||
      !jenispupuk ||
      !jumlahpupuk
    ) {
      setMsg("Semua input wajib diisi.");
      return;
    }

    console.log("Form submitted"); // Debugging

    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `https://c-greenproject.org:8000/petani`,
        {
          idlahan: selectedLahan,
          tanggalRencanaTanam,
          tanggalRencanaPanen,
          varietassingkong,
          estimasiproduksi,
          catatantambahan,
          jenispupuk,
          jumlahpupuk,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Response data:", response.data); // Debugging
      navigate("/rencanatanam");
    } catch (error) {
      console.error("Error:", error); // Debugging
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  useEffect(() => {
    fetchData(); // Ambil opsi lahan saat komponen dimuat
  }, []);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
    if (user && user.role !== "admin" && user.role !== "petani") {
      navigate("/dashboard");
    }
  }, [isError, user, navigate]);

  return (
    <Layout>
      <nav aria-label="breadcrumb pb-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/rencanatanam">Rencana Tanam</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Tambah Rencana Tanam
          </li>
        </ol>
      </nav>

      <h2 className="title">
        <strong>Tambah Rencana Tanam</strong>
      </h2>
      <div className="card is-shadowless">
        <div className="card-content ps-4 mb-3">
          <div className="content">
            <form onSubmit={savePetani}>
              <p className="has-text-centered">{msg}</p>

              <div
                className="add-lahan"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <div
                  className="column lahan"
                  style={{ flex: "1", marginRight: "10px" }}
                >
                  <div className="field mb-3">
                    <label className="label">ID Lahan</label>
                    <div className="control">
                      <div className="select">
                        <select
                          className="input"
                          value={selectedLahan}
                          onChange={(e) => setSelectedLahan(e.target.value)}
                        >
                          <option value="">Pilih Lahan</option>
                          {lahanOptions.length > 0 ? (
                            lahanOptions.map((lahan) => (
                              <option key={lahan.id} value={lahan.id}>
                                {`${lahan}`}
                              </option>
                            ))
                          ) : (
                            <option disabled>Tidak ada data lahan</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="field mb-3">
                    <label className="label">Tanggal Rencana Tanam</label>
                    <div className="control">
                      <input
                        type="date"
                        className="input"
                        value={tanggalRencanaTanam}
                        onChange={(e) => settanggalRencanaTanam(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="field mb-3">
                    <label className="label">Tanggal Rencana Panen</label>
                    <div className="control">
                      <input
                        type="date"
                        className="input"
                        value={tanggalRencanaPanen}
                        onChange={(e) => settanggalRencanaPanen(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="field mb-3">
                    <label className="label">Varietas Singkong</label>
                    <div className="control">
                      <div className="select">
                        <select
                          className="input"
                          value={varietassingkong}
                          onChange={(e) => setvarietassingkong(e.target.value)}
                        >
                          <option value="">Pilih Varietas</option>
                          <option value="Grade A">Grade A</option>
                          <option value="Grade B">Grade B</option>
                          <option value="Grade C">Grade C</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="field mb-3">
                    <label className="label">Estimasi Produksi (kg)</label>
                    <div className="control">
                      <input
                        type="number"
                        className="input"
                        value={estimasiproduksi}
                        onChange={(e) => setestimasiproduksi(e.target.value)}
                        placeholder="Estimasi Produksi"
                      />
                    </div>
                  </div>
                </div>

                <div className="column lahan" style={{ flex: "1" }}>
                  <div className="field mb-3">
                    <label className="label">Catatan Tambahan</label>
                    <div className="control">
                      <textarea
                        className="textarea"
                        value={catatantambahan}
                        onChange={(e) => setcatatantambahan(e.target.value)}
                        placeholder="Catatan Tambahan"
                      ></textarea>
                    </div>
                  </div>

                  <div className="field mb-3">
                    <label className="label">Jenis Pupuk</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={jenispupuk}
                        onChange={(e) => setjenispupuk(e.target.value)}
                        placeholder="Jenis Pupuk"
                      />
                    </div>
                  </div>

                  <div className="field mb-3">
                    <label className="label">Jumlah Pupuk (kg)</label>
                    <div className="control">
                      <input
                        type="number"
                        className="input"
                        value={jumlahpupuk}
                        onChange={(e) => setjumlahpupuk(e.target.value)}
                        placeholder="Jumlah Pupuk"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="field mt-2">
                <div className="control">
                  <button
                    type="submit"
                    className="btn btn-success button-detail"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddRencanaTanam;
