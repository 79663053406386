import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import "./component.css";
import { IoBagCheck, IoSync } from "react-icons/io5";

const ListOrderAktif = () => {
  const { user } = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `https://c-greenproject.org:8000/orderberlangsung`,
        {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        }
      );
      setProducts(response.data);
    } catch (error) {
      setMsg(error.response ? error.response.data.msg : "Error fetching data");
    }
  };

  return (
    <div>
      <h2 className="title">
        <strong>Order Berlangsung</strong>
      </h2>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr className="text-center">
            <th>No</th>
            <th>Nama Petani</th>
            <th>Varietas</th>
            <th>Status Order</th>
            <th>Tanggal Panen</th>
            <th>Estimasi Berat (kg)</th>
            <th>Harga</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {products.map((product, index) => (
            <tr key={product.uuid}>
              <td>{index + 1}</td>
              <td>{product.user?.name || "No Name"}</td>
              <td>{product.varietasSingkong}</td>
              <td>{product.statusOrder}</td>
              <td>{product.tanggalPemanenan}</td>
              <td>{product.estimasiBerat}</td>
              <td>Rp {product.estimasiHarga}</td>
              <td>
                <button
                  className="btn btn-primary button-tabel"
                  onClick={() => getProducts()}
                >
                  <IoSync /> Update
                </button>
                
                {user.role === "logistik" && product.statusOrder === "Menunggu waktu panen" && (
                  <button
                    className="btn btn-success mt-2 button-tabel"
                    onClick={() => navigate(`/logistik/transaksi/${product.uuid}`)}
                  >
                    <IoBagCheck /> Diantar
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {msg && <p className="notification is-danger">{msg}</p>}
      {loading && <p className="notification is-info">Loading...</p>}
    </div>
  );
};

export default ListOrderAktif;
