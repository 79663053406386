import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  IoBagCheck,
  IoCheckmarkCircleSharp,
  IoAddCircle,
} from "react-icons/io5";
import "./component.css";

const RencanaTanamMasuk = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth); // Mendapatkan data user dari Redux
  const [products, setProducts] = useState([]); // Data rencana tanam
  const [loading, setLoading] = useState(false); // Status loading
  const [msg, setMsg] = useState(""); // Pesan error atau informasi
  const [selectedProduct, setSelectedProduct] = useState(null); // Data untuk modal

  // Fungsi untuk mengambil data rencana tanam
  const fetchRencanaTanam = async () => {
    const token = localStorage.getItem("token"); // Mengambil token autentikasi
    setLoading(true);
    setMsg("");

    try {
      console.log("Fetching data from API...");

      const response = await axios.get(
        "https://c-greenproject.org:8000/petanis",
        {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        }
      );

      console.log("Raw Response:", response.data);

      if (Array.isArray(response.data)) {
        // Periksa apakah data memiliki struktur bersarang
        let formattedData = response.data.flatMap((item) =>
          item.rencanaTanamInfo ? item.rencanaTanamInfo : item
        );

        console.log("Formatted Data:", formattedData);

        setProducts(formattedData);
      } else {
        setMsg("Data tidak valid dari server.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setMsg(
        error.response?.data?.msg || "Gagal mengambil data. Coba lagi nanti."
      );
    } finally {
      setLoading(false);
    }
  };

  // Fungsi untuk memperbarui status rencana tanam
  const updateStatus = async (idtanam, statusRencanaTanam) => {
    const token = localStorage.getItem("token");
    setLoading(true);

    try {
      const response = await axios.patch(
        `https://c-greenproject.org:8000/rencanatanam/${idtanam}`,
        { statusRencanaTanam },
        {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        }
      );
      setMsg(response.data.msg);
      fetchRencanaTanam(); // Refresh data setelah update
    } catch (error) {
      setMsg(
        error.response?.data?.msg || "Gagal memperbarui data. Coba lagi nanti."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRencanaTanam(); // Mengambil data saat komponen pertama kali dimuat
  }, []);

  return (
    <div>
      <h2 className="title">
        <strong>Rencana Tanam Masuk</strong>
      </h2>

      {/* Tombol untuk menambah rencana tanam */}
      {user.role === "petani" && (
        <button
          className="btn btn-primary my-3"
          onClick={() => navigate("/rencanatanam/add")}
        >
          <IoAddCircle /> Tambah Rencana Tanam
        </button>
      )}

      {/* Tabel data rencana tanam */}
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr className="text-center">
            <th>No</th>
            <th>ID Tanam</th>
            <th>Status</th>
            <th>Varietas Singkong</th>
            <th>Estimasi Produksi (kg)</th>
            <th>Tanggal Rencana Tanam</th>
            <th>Tanggal Rencana Panen</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {products.length > 0 ? (
            products.map((product, index) => (
              <tr key={product.idtanam || `product-${index}`}>
                <td>{index + 1}</td>
                <td>{product.idtanam}</td>
                <td>{product.statusRencanaTanam}</td>
                <td>{product.varietassingkong}</td>
                <td>{product.estimasiproduksi}</td>
                <td>{product.tanggalRencanaTanam}</td>
                <td>{product.tanggalRencanaPanen}</td>
                <td>
                  {user.role === "perusahaan" && (
                    <>
                      <button
                        className="btn btn-success mt-2 button-tabel"
                        onClick={() =>
                          updateStatus(product.idtanam, "Rencana Tanam Disetujui")
                        }
                      >
                        <IoBagCheck /> Setujui
                      </button>

                      <button
                        className="btn btn-danger mt-2 button-tabel"
                        onClick={() =>
                          updateStatus(product.idtanam, "Rencana Tanam Ditolak")
                        }
                      >
                        <IoCheckmarkCircleSharp /> Tolak
                      </button>
                    </>
                  )}
                  
                  <button
                    className="btn btn-info mt-2 button-tabel"
                    onClick={() => setSelectedProduct(product)}
                    data-bs-toggle="modal"
                    data-bs-target="#detailModal"
                  >
                    Detail
                  </button>
                </td>
              </tr>
            ))
          ) : (
            !loading && (
              <tr>
                <td colSpan="8" className="text-center">
                  Tidak ada data tersedia.
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>

      {/* Pesan jika tidak ada data */}
      {msg && <p className="notification is-danger">{msg}</p>}

      {/* Loader saat loading */}
      {loading && <p className="notification is-info">Loading...</p>}

      {/* Modal untuk detail rencana tanam */}
      {selectedProduct && (
        <div
          className="modal fade"
          id="detailModal"
          tabIndex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="detailModalLabel">
                  Detail Rencana Tanam
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  <strong>Jenis Pupuk:</strong> {selectedProduct.jenispupuk}
                </p>
                <p>
                  <strong>Jumlah Pupuk:</strong> {selectedProduct.jumlahpupuk} kg
                </p>
                <p>
                  <strong>Catatan Tambahan:</strong> <br />
                  {selectedProduct.catatantambahan}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RencanaTanamMasuk;
