import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../../page/layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";

const PetaniLahan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, user } = useSelector((state) => state.auth);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
    if (user && user.role !== "admin" && user.role !== "petani") {
      navigate("/dashboard");
    }
  }, [isError, user, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get(
          `https://c-greenproject.org:8000/getpetanidasar`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (user?.uuid) {
      fetchData();
    }
  }, [user?.uuid]);

  const deleteLahan = async (id) => {
    if (window.confirm("Apakah Anda yakin ingin menghapus data ini?")) {
      const token = localStorage.getItem("token");

      try {
        await axios.delete(`https://c-greenproject.org:8000/petani/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const updatedUserData = userData.filter((data) => data.id !== id);
        setUserData(updatedUserData);
        alert("Data berhasil dihapus.");
      } catch (error) {
        console.error("Error deleting data:", error);
        alert("Terjadi kesalahan saat menghapus data.");
      }
    }
  };

  return (
    <Layout>
      <div>
        <h2 className="title">
          <strong>Data Lahan Petani</strong>
        </h2>
        {user && (user.role === "admin" || user.role === "petani") && (
          <Link to="/datalahan/add" className="btn btn-primary mb-2">
            Add Data Lahan Petani
          </Link>
        )}
        {userData.length ? (
          userData.map((data, index) => (
            <div key={index}>
              <div className="card ps-4 pt-4 pb-4 mb-4">
                {user && (user.role === "admin" || user.role === "perusahaan") && (
                  <div className="col-8 d-flex">
                    <table>
                      <tbody className="h4">
                        <tr>
                          <th>Nama Petani</th>
                          <td>:</td>
                          <td>{data.user.name}</td>
                        </tr>
                        <tr>
                          <th>Email Petani</th>
                          <td>:</td>
                          <td>{data.user.email}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                <p className="poin">
                  <b>1. Informasi Lahan</b>
                </p>
                <div className="subpoin">
                  <tr>
                    <td>ID Lahan</td>
                    <td>:</td>
                    <td>{data.idlahan}</td>
                  </tr>
                  <tr>
                    <td>Lokasi Lahan</td>
                    <td>:</td>
                    <td>{data.lokasilahan}</td>
                  </tr>
                  <tr>
                    <td>Luas Lahan</td>
                    <td>:</td>
                    <td>{data.luaslahan}</td>
                  </tr>
                  <tr>
                    <td>Kategori Lahan</td>
                    <td>:</td>
                    <td>{data.kategorilahan}</td>
                  </tr>
                </div>

                <p className="poin mt-3">
                  <b>2. Data Produksi</b>
                </p>
                <div className="subpoin">
                  <tr>
                    <td>Periode Tanam Mulai</td>
                    <td>:</td>
                    <td>{data.periodeTanamMulai}</td>
                  </tr>
                  <tr>
                    <td>Periode Tanam Selesai</td>
                    <td>:</td>
                    <td>{data.periodeTanamSelesai}</td>
                  </tr>
                </div>

                <div>
                  {user && (user.role === "admin" || user.role === "petani") && (
                    <Link
                      to={`/datalahan/edit/${data.id}`}
                      className="btn btn-info button-detail"
                    >
                      Edit
                    </Link>
                  )}

                  {user && user.role === "admin" && (
                    <button
                      onClick={() => deleteLahan(data.id)}
                      className="btn btn-danger button-detail ms-2"
                    >
                      Hapus
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Loading user details...</p>
        )}
      </div>
    </Layout>
  );
};

export default PetaniLahan;
