import React, { useEffect, useState } from "react";
import Layout from "./layout";
import RencanaTanamMasuk from "../component/RencanaTanamMasuk";
import RencanaTanamAktif from "../component/RencanaTanamAktif";
import HistoryRencanaTanam from "../component/HistoryRencanaTanam";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../features/authSlice";

const TABS = [
  { id: "masuk", label: "Rencana Tanam Masuk", component: <RencanaTanamMasuk /> },
  { id: "aktif", label: "Rencana Tanam Aktif", component: <RencanaTanamAktif /> },
  { id: "history", label: "History Rencana Tanam", component: <HistoryRencanaTanam /> },
];

const RencanaTanam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError } = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState("masuk");

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
  }, [isError, navigate]);

  return (
    <Layout>
      <div className="tabs">
        {TABS.map((tab) => (
          <button
            key={tab.id}
            className={`tab ${activeTab === tab.id ? "active" : ""}`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="content-tanam">
        {TABS.find((tab) => tab.id === activeTab)?.component}
      </div>
    </Layout>
  );
};

export default RencanaTanam;
