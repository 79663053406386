import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";

const LogistikList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, user } = useSelector((state) => state.auth);
  const [userData, setUserData] = useState([]);
  const [userAuth, setUserAuth] = useState(null);

  useEffect(() => {
    // Pastikan user dan user.uuid tersedia sebelum melakukan fetch
    if (user?.uuid) {
      const fetchData = async () => {
        const token = localStorage.getItem("token");

        try {
          const response = await axios.get(
            `https://c-greenproject.org:8000/users/${user.uuid}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setUserAuth(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [user?.uuid]); // Tambahkan user?.uuid sebagai dependency untuk lebih aman

  useEffect(() => {
    if (user?.uuid) {
      const fetchData = async () => {
        const token = localStorage.getItem("token");

        try {
          const response = await axios.get(
            `https://c-greenproject.org:8000/logistikdasar`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setUserData(response.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [user?.uuid]);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
    if (user && user.role !== "logistik") {
      navigate("/data-logistik");
    }
  }, [isError, user, navigate]);

  const deleteProduct = async (id) => {
    // Tampilkan dialog konfirmasi penghapusan
    if (window.confirm("Apakah Anda yakin ingin menghapus data ini?")) {
      const token = localStorage.getItem("token");

      try {
        await axios.delete(`https://c-greenproject.org:8000/logistikdasar/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Filter out the deleted data from the userData state
        const updatedUserData = userData.filter((data) => data.id !== id);
        setUserData(updatedUserData); // Set the state with the filtered data
        // Tampilkan pesan sukses atau lakukan sesuatu sebagai konfirmasi penghapusan
        alert("Data berhasil dihapus.");
      } catch (error) {
        console.error("Error deleting data:", error);
        // Handle error, e.g., show an error message
        alert("Terjadi kesalahan saat menghapus data.");
      }
    }
  };

  const formatNumber = (number) => {
    return number.toLocaleString("id-ID");
  };
  return (
    <Layout>
      <div>
        <h2 className="title">
          {" "}
          <strong>Data Logistik</strong>
        </h2>
        {user && (user.role === "admin" || user.role === "logistik") && (
          <Link to="/data-logistik/add" className="btn btn-primary mb-2">
            Add Data Logistik
          </Link>
        )}

        {userData.length ? (
          userData.map((data, index) => (
            <div key={index}>
              <div className="card ps-4 pt-4 pb-4 mb-4">
                {user &&
                  (user.role === "admin" || user.role === "perusahaan") && (
                    <div className="col-8 d-flex">
                      <table>
                        <tbody>
                          <tr className="h4">
                            <th>Nama Logistik</th>
                            <td>:</td>
                            <td>{user.name}</td>
                          </tr>
                          <tr className="h4">
                            <th>Email Logistik</th>
                            <td>:</td>
                            <td>{user.email}</td>
                          </tr>
                          <tr className="h4">
                            <th>User ID Logistik</th>
                            <td>:</td>
                            <td>{user.uuid}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                <div className="subpoin">
                  <table>
                    <tbody>
                      <tr>
                        <td><b>ID Kendaraan</b></td>
                        <td>:</td>
                        <td>{data.idKendaraan}</td>
                      </tr>
                      <tr>
                        <td>Asal Lokasi</td>
                        <td>:</td>
                        <td>{data.asal}</td>
                      </tr>
                      <tr>
                        <td>Nomor Plat</td>
                        <td>:</td>
                        <td>{data.PolisiKendaraan}</td>
                      </tr>
                      <tr>
                        <td>Jenis Kendaraan</td>
                        <td>:</td>
                        <td>{data.jenisKendaraan}</td>
                      </tr>
                      <tr>
                        <td>Kapasitas Angkut</td>
                        <td>:</td>
                        <td>{formatNumber (data.kapasitasAngkut)} kg</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="mt-3">
                  {user &&
                    (user.role === "admin" || user.role === "logistik") && (
                      <Link
                        to={`/data-logistik/edit/${data.id}`}
                        className="btn btn-info button-detail"
                      >
                        Edit
                      </Link>
                    )}
                  {user.role === "admin" && (
                    <button
                      onClick={() => deleteProduct(data.id)}
                      className="btn btn-danger button-detail ms-2"
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Loading user details...</p>
        )}
      </div>
    </Layout>
  );
};

export default LogistikList;
