import React, { useEffect, useState } from "react";
import Layout from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import axios from "axios";

const LogistikListEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isError, user } = useSelector((state) => state.auth);

  // States untuk setiap field data logistik
  const [asal, setAsal] = useState("");
  const [PolisiKendaraan, setPolisiKendaraan] = useState("");
  const [jenisKendaraan, setjenisKendaraan] = useState("");
  const [kapasitasAngkut, setkapasitasAngkut] = useState("");
  const [msg, setMsg] = useState("");

  useEffect(() => {
    const fetchLogistikData = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get(
          `https://c-greenproject.org:8000/logistikdasar/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data;
        setAsal(data.asal);
        setPolisiKendaraan(data.PolisiKendaraan);
        setjenisKendaraan(data.jenisKendaraan);
        setkapasitasAngkut(data.kapasitasAngkut.toString());
      } catch (error) {
        setMsg(
          error?.response?.data?.msg || "Terjadi kesalahan saat mengambil data"
        );
      }
    };

    fetchLogistikData();
  }, [id]);

  const updateLogistikData = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    try {
      await axios.put(
        `https://c-greenproject.org:8000/logistikdasar/${id}`,
        {
          asal,
          PolisiKendaraan,
          jenisKendaraan,
          kapasitasAngkut: Number(kapasitasAngkut),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/data-logistik"); // Sesuaikan sesuai dengan route yang diinginkan
    } catch (error) {
      setMsg(
        error?.response?.data?.message ||
          "Terjadi kesalahan saat memperbarui data"
      );
    }
  };

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
    if (user && user.role !== "admin" && user.role !== "logistik") {
      navigate("/dashboard");
    }
  }, [isError, user, navigate]);

  return (
    <Layout>
      <nav aria-label="breadcrumb pb-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/data-logistik">Data Logistik</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Edit Data Logistik
          </li>
        </ol>
      </nav>

      <h2 className="title">
        {" "}
        <strong>Edit Data Logistik</strong>
      </h2>
      <div className="card is-shadowless">
        <div className="card-content ps-4 mb-3">
          <div className="content">
            <form onSubmit={updateLogistikData}>
              <p className="has-text-centered">{msg}</p>

              <div className="add-logistik">
                <div className="column logistik">
                  <div className="field mb-2">
                    <label className="label">Asal</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={asal}
                        onChange={(e) => setAsal(e.target.value)}
                        placeholder="Asal"
                      />
                    </div>
                  </div>

                  <div className="field mb-2">
                    <label className="label">Nomor Polisi Kendaraan</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={PolisiKendaraan}
                        onChange={(e) => setPolisiKendaraan(e.target.value)}
                        placeholder="Nomor Polisi Kendaraan"
                      />
                    </div>
                  </div>

                  <div className="field mb-2">
                    <label className="label">Jenis Kendaraan</label>
                    <div className="control">
                      <input
                        type="text"
                        className="input"
                        value={jenisKendaraan}
                        onChange={(e) => setjenisKendaraan(e.target.value)}
                        placeholder="Jenis Kendaraan"
                      />
                    </div>
                  </div>

                  <div className="field mb-2">
                    <label className="label">Kapasitas Angkut (kg)</label>
                    <div className="control">
                      <input
                        type="number"
                        className="input"
                        value={kapasitasAngkut}
                        onChange={(e) => setkapasitasAngkut(e.target.value)}
                        placeholder="Kapasitas Angkut"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="field mt-3">
                <div className="control">
                  <button
                    type="submit"
                    className="btn btn-success button-detail"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LogistikListEdit;
