import React, { useEffect, useState } from "react";
import Layout from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/authSlice";
import axios from "axios";

const PabrikAddList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError, user } = useSelector((state) => state.auth);

  // Initialize state for each field in the pabrik data form
  const [kapasitasram, setkapasitasram] = useState("");
  const [kapasitasproduksi, setkapasitasproduksi] = useState("");
  const [memproduksi, setmemproduksi] = useState("");
  const [msg, setMsg] = useState("");

  const savePabrik = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    try {
      await axios.post(
        `https://c-greenproject.org:8000/pabrik`,
        {
          kapasitasram,
          kapasitasproduksi,
          memproduksi,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/data-pabrik");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      navigate("/");
    }
    // Adjust this condition based on your application's needs
    if (user && user.role !== "admin" && user.role !== "pabrik") {
      navigate("/dashboard");
    }
  }, [isError, user, navigate]);

  return (
    <Layout>
      <nav aria-label="breadcrumb pb-0">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/data-pabrik">Data Pabrik</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Add Data Pabrik
          </li>
        </ol>
      </nav>

      <h2 className="title">
        <strong>Add Data Pabrik</strong>
      </h2>
      <div className="card is-shadowless">
        <div className="card-content ps-4 mb-3">
          <div className="content">
            <form onSubmit={savePabrik}>
              <p className="has-text-centered">{msg}</p>

              <div className="field mb-2">
                <label className="label">Kapasitas RAMP (kg)</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    value={kapasitasram}
                    onChange={(e) => setkapasitasram(e.target.value)}
                    placeholder="Kapasitas RAMP"
                  />
                </div>
              </div>

              <div className="field mb-2">
                <label className="label">Kapasitas Produksi (kg)</label>
                <div className="control">
                  <input
                    type="number"
                    className="input"
                    value={kapasitasproduksi}
                    onChange={(e) => setkapasitasproduksi(e.target.value)}
                    placeholder="Kapasitas Produksi"
                  />
                </div>
              </div>

              <div className="field mb-2">
                <label className="label">Catatan Produksi Pabrik</label>
                <div className="control">
                  <input
                    type="text"
                    className="input"
                    value={memproduksi}
                    onChange={(e) => setmemproduksi(e.target.value)}
                    placeholder="Catatan Produksi Pabrik"
                  />
                </div>
              </div>
              
              <div className="field mt-3">
                <div className="control">
                  <button
                    type="submit"
                    className="btn btn-success button-detail"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PabrikAddList;
