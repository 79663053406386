import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./component.css";

const HistoryRencanaTanam = () => {
  const { user } = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fungsi untuk mengambil data rencana tanam
  const fetchRencanaTanam = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      setMsg("Token tidak ditemukan. Silakan login ulang.");
      return;
    }

    setLoading(true);
    setMsg("");

    try {
      console.log("Fetching data from API...");

      const response = await axios.get(
        `https://c-greenproject.org:8000/historytanam`,
        {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        }
      );

      console.log("Raw Response:", response.data);

      if (Array.isArray(response.data)) {
        // Periksa apakah API mengembalikan array langsung atau dalam struktur objek
        let formattedData = response.data.flatMap((item) =>
          item.rencanaTanamInfo ? item.rencanaTanamInfo : item
        );

        console.log("Formatted Data:", formattedData);

        setProducts(formattedData);
      } else {
        setMsg("Data tidak valid dari server.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setMsg(
        error.response?.data?.msg || "Gagal mengambil data. Coba lagi nanti."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchRencanaTanam();
    }
  }, [user]);

  // Fungsi untuk membuka modal
  const handleShowModal = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  // Fungsi untuk menutup modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };

  return (
    <div>
      <h2 className="title">
        <strong>History Rencana Tanam</strong>
      </h2>

      {msg && <p className="notification is-danger">{msg}</p>}

      {/* Tabel */}
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr className="text-center">
            <th>No</th>
            <th>ID Tanam</th>
            <th>Status</th>
            <th>Varietas Singkong</th>
            <th>Estimasi Produksi (kg)</th>
            <th>Tanggal Rencana Tanam</th>
            <th>Tanggal Rencana Panen</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {products.length > 0 ? (
            products.map((product, index) => (
              <tr key={product.idtanam || `tanam-${index}`}>
                <td>{index + 1}</td>
                <td>{product.idtanam}</td>
                <td>{product.statusRencanaTanam}</td>
                <td>{product.varietassingkong}</td>
                <td>{product.estimasiproduksi}</td>
                <td>{product.tanggalRencanaTanam}</td>
                <td>{product.tanggalRencanaPanen}</td>
                <td>
                  <Button
                    variant="info"
                    onClick={() => handleShowModal(product)}
                    className="button-tabel"
                  >
                    Detail
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            !loading && (
              <tr>
                <td colSpan="8" className="text-center">
                  Tidak ada data tersedia.
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>

      {/* Loader */}
      {loading && <p className="notification is-info">Loading...</p>}

      {/* Modal Detail */}
      {selectedProduct && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Detail Rencana Tanam</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <strong>Jenis Pupuk:</strong> {selectedProduct.jenispupuk}
            </p>
            <p>
              <strong>Jumlah Pupuk:</strong> {selectedProduct.jumlahpupuk} kg
            </p>
            <p>
              <strong>Catatan Tambahan:</strong> <br />
              {selectedProduct.catatantambahan}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Tutup
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default HistoryRencanaTanam;
