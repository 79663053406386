import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { IoBagCheck, IoCheckmarkCircleSharp, IoSync } from "react-icons/io5";
import "./component.css";

const RencanaTanamAktif = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Fungsi untuk mengambil data
  const fetchRencanaTanam = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    setMsg(""); // Reset pesan error
    try {
      const response = await axios.get(`https://c-greenproject.org:8000/petanisapproved`, {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true,
      });

      if (Array.isArray(response.data)) {
        // Periksa apakah data memiliki struktur bersarang
        let formattedData = response.data.flatMap((item) =>
          item.rencanaTanamInfo ? item.rencanaTanamInfo : item
        );

        console.log("Formatted Data:", formattedData);

        setProducts(formattedData);
      } else {
        setMsg("Data tidak valid dari server.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setMsg(
        error.response?.data?.msg || "Gagal mengambil data. Coba lagi nanti."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRencanaTanam();
  }, []);

  return (
    <div>
      <h2 className="title">
        <strong>Rencana Tanam Masuk</strong>
      </h2>

      {/* Menampilkan loading atau pesan */}
      {loading && <p className="notification is-info">Memuat data...</p>}
      {msg && <p className="notification is-danger">{msg}</p>}

      {/* Tabel */}
      {products.length > 0 ? (
        <table className="table is-striped is-fullwidth">
          <thead>
            <tr className="text-center">
              <th>No</th>
              <th>ID Tanam</th>
              <th>Status</th>
              <th>Varietas Singkong</th>
              <th>Estimasi Produksi (kg)</th>
              <th>Tanggal Rencana Tanam</th>
              <th>Tanggal Rencana Panen</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {products.map((product, index) => (
              <tr key={product.idtanam || index}> {/* Gunakan index jika idtanam tidak unik */}
                <td>{index + 1}</td>
                <td>{product.idtanam}</td>
                <td>{product.statusRencanaTanam}</td>
                <td>{product.varietassingkong}</td>
                <td>{product.estimasiproduksi}</td>
                <td>{product.tanggalRencanaTanam}</td>
                <td>{product.tanggalRencanaPanen}</td>
                <td>
                  {user?.role === "petani" && (
                    <>
                      <button
                        className="btn btn-success mt-2 button-tabel"
                      >
                        <IoSync /> Update
                      </button>
                    </>
                  )}
                  <button
                    className="btn btn-info mt-2 button-tabel"
                    onClick={() => setSelectedProduct(product)}
                    data-bs-toggle="modal"
                    data-bs-target="#detailModal"
                  >
                    Detail
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        !loading && <p className="notification is-warning">Tidak ada data tersedia.</p>
      )}

      {/* Modal Detail */}
      {selectedProduct && (
        <div
          className="modal fade"
          id="detailModal"
          tabIndex="-1"
          aria-labelledby="detailModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="detailModalLabel">
                  Detail Rencana Tanam
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  <strong>Jenis Pupuk:</strong> {selectedProduct.jenispupuk}
                </p>
                <p>
                  <strong>Jumlah Pupuk:</strong> {selectedProduct.jumlahpupuk} kg
                </p>
                <p>
                  <strong>Catatan Tambahan:</strong> <br />
                  {selectedProduct.catatantambahan}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RencanaTanamAktif;
