import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../layout";

const LogistikTransaksi = () => {
  const { id } = useParams(); // Mengambil ID order dari URL
  const navigate = useNavigate();
  const [aktualWaktuTiba, setAktualWaktuTiba] = useState("");
  const [biayaTransportasi, setBiayaTransportasi] = useState("");
  const [kondisiPengiriman, setKondisiPengiriman] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!id) {
      setMsg("ID produk tidak ditemukan.");
    }
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!id) {
      setMsg("ID produk tidak ditemukan.");
      return;
    }
    setLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
        aktualWaktuTiba, // Kirim langsung string "HH:MM" dari input tanpa konversi ke Date
        biayaTransportasi: parseFloat(biayaTransportasi), // Memastikan dikirim sebagai angka
        kondisiPengiriman,
      };
    try {
      await axios.post(
        `https://c-greenproject.org:8000/transaksi-logistik/${id}`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      navigate("/orderaktif");
    } catch (error) {
      console.error("Error response:", error.response); // Logging error ke console
      setMsg(error.response?.data?.msg || "Gagal mencatat transaksi logistik.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div>
        <h2 className="title pt-0">
          <strong>Input Data Logistik</strong>
        </h2>
        <div className="card is-shadowless">
          <div className="card-content">
            <div className="content ps-4 pt-2">
              <form onSubmit={handleSubmit}>
                <p className="has-text-centered text-danger">{msg}</p>

                <div className="field mb-3">
                  <label className="label">Aktual Waktu Tiba</label>
                  <div className="control">
                    <input
                      type="time"
                      className="input"
                      value={aktualWaktuTiba}
                      onChange={(e) => setAktualWaktuTiba(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="field mb-3">
                  <label className="label">Biaya Transportasi</label>
                  <div className="control">
                    <input
                      type="number"
                      className="input"
                      value={biayaTransportasi}
                      onChange={(e) => setBiayaTransportasi(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="field mb-3">
                  <label className="label">Kondisi Pengiriman</label>
                  <div className="control">
                    <textarea
                      className="input"
                      value={kondisiPengiriman}
                      onChange={(e) => setKondisiPengiriman(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="field mb-4">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading ? "Mengirim..." : "Kirim Data"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LogistikTransaksi;
