import React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import {
  IoPerson,
  IoHome,
  IoLogOut,
  IoBusiness,
  IoLeaf,
  IoAirplane,
  IoCreate,
  IoBagHandle,
  IoHourglass,
  IoNotifications,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { LogOut, reset } from "../features/authSlice";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./sidebar.css";
import logo from "../logo.png";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();

  // Logout function
  const logout = () => {
    dispatch(LogOut());
    dispatch(reset());
    navigate("/login");
  };

  // Ensure user is defined before accessing its properties
  const userRole = user ? user.role : null;

  return (
    <div>
      <aside className="menu pl-2 has-shadow" id="sidebar">
        <div className="col text-center">
          <img
            src={logo}
            alt="Cassava Super Logo"
            style={{ width: "30%" }}
            className="logo-sidebar"
          />
        </div>
        <div className="sidebar-logo text-center pt-2">
          <h3>
            <strong>Cassava Super</strong>
          </h3>
        </div>

        <p className="menu-label">General</p>
        <ul className="menu-list">
          <li>
            <NavLink to={"/dashboard"}>
              <IoHome /> Dashboard
            </NavLink>
          </li>

          {/* Conditional rendering based on user role */}
          {(userRole === "petani" || userRole === "perusahaan") && (
            <li className="mt-3">
              <NavLink to={"/rencanatanam"}>
                <IoCreate />
                Rencana Tanam
              </NavLink>
            </li>
          )}

          {userRole !== "petani" && (
            <li className="mt-3">
              <NavLink to={"/ordermasuk"}>
                <IoNotifications />
                Order Masuk
              </NavLink>
            </li>
          )}

          {userRole === "petani" && (
            <li className="mt-3">
              <NavLink to={"/addorder"}>
                <IoNotifications />
                Add Order
              </NavLink>
            </li>
          )}

          <li className="mt-3">
            <NavLink to={"/orderaktif"}>
              <IoBagHandle />
              Order Aktif
            </NavLink>
          </li>

          <li className="mt-3">
            <NavLink to={"/history-panen"}>
              <IoHourglass />
              History Order
            </NavLink>
          </li>
        </ul>

        {/* Only show Users section for admins */}
        {userRole === "admin" && (
          <div>
            <p className="menu-label">Users</p>
            <ul className="menu-list">
              <li>
                <NavLink to={"/users"}>
                  <IoPerson /> Users
                </NavLink>
              </li>
            </ul>
          </div>
        )}

        {/* Profile section based on user role */}
        {userRole && userRole !== "admin" && (
          <div>
            <p className="menu-label">Profile</p>
            {userRole === "perusahaan" && (
              <ul className="menu-list">
                <li>
                  <NavLink to={"/profile-perusahaan"}>
                    <IoPerson /> Profile Perusahaan
                  </NavLink>
                </li>
              </ul>
            )}

            {userRole === "petani" && (
              <ul className="menu-list">
                <li>
                  <NavLink to={"/profile-petani"}>
                    <IoPerson /> Profile Petani
                  </NavLink>
                </li>
              </ul>
            )}

            {userRole === "pabrik" && (
              <ul className="menu-list">
                <li>
                  <NavLink to={"/profile-pabrik"}>
                    <IoPerson /> Profile Pabrik
                  </NavLink>
                </li>
              </ul>
            )}

            {userRole === "logistik" && (
              <ul className="menu-list">
                <li>
                  <NavLink to={"/profile-logistik"}>
                    <IoPerson /> Profile Logistik
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
        )}

        {/* Data sections */}
        <div>
          <p className="menu-label">Data-data</p>
          {(userRole === "admin" || userRole === "perusahaan" || userRole === "petani") && (
            <ul className="menu-list">
              <li>
                <NavLink to={"/datalahan"}>
                  <IoLeaf /> Data Petani
                </NavLink>
              </li>
            </ul>
          )}

          {(userRole === "admin" || userRole === "perusahaan" || userRole === "pabrik") && (
            <ul className="menu-list">
              <li>
                <NavLink to={"/data-pabrik"}>
                  <IoBusiness /> Data Pabrik
                </NavLink>
              </li>
            </ul>
          )}

          {(userRole === "admin" || userRole === "perusahaan" || userRole === "logistik") && (
            <ul className="menu-list">
              <li>
                <NavLink to={"/data-logistik"}>
                  <IoAirplane /> Data Logistik
                </NavLink>
              </li>
            </ul>
          )}
        </div>

        {/* Logout button */}
        <ul className="button text-center">
          <li>
            <button onClick={logout} className="btn btn-danger">
              <IoLogOut /> Logout
            </button>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
