import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../layout";

const LogistikApprove = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [idKendaraan, setidKendaraan] = useState("");
  const [estimasiWaktuTiba, setEstimasiWaktuTiba] = useState("");
  const [waktuPengiriman, setWaktuPengiriman] = useState("");
  const [tanggalPengiriman, setTanggalPengiriman] = useState("");
  const [kendaraanOptions, setKendaraanOptions] = useState([]);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  // Ambil productId dari query string
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("productId");

  useEffect(() => {
    if (!productId) {
      setMsg("ID produk tidak ditemukan.");
    }
  }, [productId]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get("https://c-greenproject.org:8000/idKendaraan/", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setKendaraanOptions(response.data);
      } catch (error) {
        setMsg("Gagal mengambil data kendaraan logistik.");
      }
    };
    fetchData();
  }, []);

  const handleApprove = async (e) => {
    e.preventDefault();
    if (!productId) {
      setMsg("ID produk tidak ditemukan.");
      return;
    }
    setLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      idKendaraan,
      estimasiWaktuTiba,
      waktuPengiriman,
      tanggalPengiriman,
      logistik: true,
    };
    try {
      await axios.patch(`https://c-greenproject.org:8000/products/${productId}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      navigate("/orderaktif");
    } catch (error) {
      setMsg(error.response?.data?.msg || "Gagal mengubah status logistik.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div>
        <h2 className="title pt-0">
          <strong>Logistik Approval Form</strong>
        </h2>
        <div className="card is-shadowless">
          <div className="card-content">
            <div className="content ps-4 pt-2">
              <form onSubmit={handleApprove}>
                <p className="has-text-centered text-danger">{msg}</p>
                <div className="field mb-3">
                  <label className="label">Pilih Kendaraan</label>
                  <div className="control">
                    <div className="select">
                      <select
                        className="input"
                        value={idKendaraan}
                        onChange={(e) => setidKendaraan(e.target.value)}
                        required
                      >
                        <option value="">Pilih Kendaraan</option>
                        {kendaraanOptions.length > 0 ? (
                          kendaraanOptions.map((kendaraan) => (
                            <option key={kendaraan.idKendaraan} value={kendaraan.idKendaraan}>
                              {kendaraan.idKendaraan}
                            </option>
                          ))
                        ) : (
                          <option disabled>Data kendaraan tidak tersedia</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field mb-3">
                  <label className="label">Tanggal Pengiriman</label>
                  <div className="control">
                    <input
                      type="date"
                      className="input"
                      value={tanggalPengiriman}
                      onChange={(e) => setTanggalPengiriman(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="field mb-3">
                  <label className="label">Waktu Pengiriman</label>
                  <div className="control">
                    <input
                      type="time"
                      className="input"
                      value={waktuPengiriman}
                      onChange={(e) => setWaktuPengiriman(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="field mb-3">
                  <label className="label">Estimasi Waktu Tiba</label>
                  <div className="control">
                    <input
                      type="time"
                      className="input"
                      value={estimasiWaktuTiba}
                      onChange={(e) => setEstimasiWaktuTiba(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="field mb-4">
                  <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? "Mengirim..." : "Setujui"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LogistikApprove;
