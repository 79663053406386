import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import "./component.css";
import { Container, Row, Col } from "react-bootstrap";

const JumlahVarietas = () => {
  const [gradeData, setGradeData] = useState({ GradeA: 0, GradeB: 0, GradeC: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await axios.get("https://c-greenproject.org:8000/jumlahvarietas", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setGradeData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err?.response?.data?.msg || "Gagal memuat data.");
        setLoading(false);
      }
    };

    if (user) {
      fetchData();
    }
  }, [user]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="text-danger">{error}</p>;
  }

  return (
    <div className="grade-card mb-4">
      <Container>
        <Row>
          <Col className="text-center">
            <h5><b>Grade A</b></h5>
            <h2><b>{gradeData.GradeA}</b></h2>
            <p>Ditanam</p>
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            <div className="vertical-divider"></div>
          </Col>
          <Col className="text-center">
            <h5><b>Grade B</b></h5>
            <h2><b>{gradeData.GradeB}</b></h2>
            <p>Ditanam</p>
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            <div className="vertical-divider"></div>
          </Col>
          <Col className="text-center">
            <h5><b>Grade C</b></h5>
            <h2><b>{gradeData.GradeC}</b></h2>
            <p>Ditanam</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default JumlahVarietas;
